import cn from "@/utils/cn";

import EmailCaptureForm from "./email-capture-form";
import { MarketingFormProps } from "../utils";
import { PrismicRichText } from "@prismicio/react";
import { PrismicNextImage } from "@prismicio/next";
import Image from "next/image";
import MobileBG from "@/assets/landing-page-mobile-bg.svg";
import TextComponent from "@/app/_components/TextComponent";
import {
  HeroBackgroundImageLeft,
  HeroBackgroundImageRight,
} from "@/assets/svg-icons/svg-icons";
import ScrollToAuditFormButton from "@/components/ScrollToAuditFormButton";
type HeroSectionProps = {
  contentTitle: MarketingFormProps["slice"]["primary"]["title"];
  contentDescription: MarketingFormProps["slice"]["primary"]["description"];
  backgroundImage: MarketingFormProps["slice"]["primary"]["background_image"];
  connectorImage: MarketingFormProps["slice"]["primary"]["connector_image"];
} & React.HTMLAttributes<HTMLDivElement>;

export default function HeroSection({
  contentTitle,
  contentDescription,
  backgroundImage,
  connectorImage,
  className,
  ...props
}: HeroSectionProps) {
  return (
    <div
      className={cn(
        "flex overflow-x-hidden flex-col -mb-6 xl:-mb-20 md:-mb-10 items-center relative",
        className,
      )}
      {...props}
    >
      {/* <div className=" absolute -z-10  w-screen bg-black">
        <PrismicNextImage
          // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
          // className="absolute object-fill -z-10 -mt-[420px] min-w-[1280px] md:-mt-[512px] xl:-mt-[300px]"
          // className="object-cover object-bottom h-[480px] md:h-[436.81px] lg:h-full "
          className={cn(
            "hidden object-coverobject-bottom md:block  lg:h-full",
            "md:h-[436.81px] h-[480px]",
            "w-full",
          )}
          field={backgroundImage}
          alt=""
          priority
        />
      </div>
      <Image
        src={MobileBG}
        alt=""
        className="absolute inset-x-0 bottom-0 top-1 -z-10 h-[70%] w-full object-cover md:hidden "
      /> */}
      <div
        className={cn(
          "absolute -z-10 flex h-[450px] w-screen justify-between overflow-hidden bg-kd-lte-blue md:h-[407px] xl:h-[607px]",
        )}
      >
        <div className="relative h-full w-1/2">
          <HeroBackgroundImageLeft className="absolute -left-1/3 h-full w-auto md:-left-8 lg:left-0" />
        </div>
        <div className="relative h-full w-1/2">
          <HeroBackgroundImageRight className="absolute -right-1/3 h-full w-auto md:-right-8 lg:right-0" />
        </div>
      </div>
      <div className="mt-20 w-[70%] md:w-1/2 xl:mt-44">
        <PrismicRichText
          field={contentTitle}
          components={{
            paragraph: ({ children }) => {
              return (
                <TextComponent
                  type="title"
                  className="text-center text-kd-white"
                >
                  {children}
                </TextComponent>
              );
            },
          }}
        />
      </div>
      <div className="mt-[7px] w-[70%] md:mt-3 md:w-1/2 xl:mt-[17px]">
        <PrismicRichText
          field={contentDescription}
          components={{
            paragraph: ({ children }) => {
              return (
                <TextComponent type="h4" className="text-center text-kd-white">
                  {children}
                </TextComponent>
              );
            },
          }}
        />
      </div>
      {/* <EmailCaptureForm className="mt-4 flex items-start space-x-4" /> */}
      <ScrollToAuditFormButton />
      <PrismicNextImage
        field={connectorImage}
        className={cn("w-4/5 md:w-3/5")}
        alt=""
      />
    </div>
  );
}
