"use client";

import React from "react";
import Button from "./buttons";

const ScrollToAuditFormButton: React.FC = () => {
  const scrollToAuditForm = (): void => {
    const element = document.getElementById("audit-report-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Button
      variant="secondary"
      className="my-[30px] bg-white text-kd-lte-blue hover:bg-white/80"
      onClick={scrollToAuditForm}
    >
      Get a Free Audit
    </Button>
  );
};

export default ScrollToAuditFormButton;
