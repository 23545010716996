import HeroSection from "./hero-section";
import { MarketingFormProps } from "./utils";

/**
 * Component for "MarketingForm" Slices.
 */
const MarketingForm = ({ slice }: MarketingFormProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <HeroSection
        contentTitle={slice.primary.title}
        contentDescription={slice.primary.description}
        backgroundImage={slice.primary.background_image}
        connectorImage={slice.primary.connector_image}
      />
    </section>
  );
};

export default MarketingForm;
